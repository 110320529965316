import { render, staticRenderFns } from "./sellers.vue?vue&type=template&id=197eb628&scoped=true"
import script from "./sellers.vue?vue&type=script&lang=ts"
export * from "./sellers.vue?vue&type=script&lang=ts"
import style0 from "./sellers.vue?vue&type=style&index=0&id=197eb628&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "197eb628",
  null
  
)

export default component.exports