
import { Component, Vue } from "vue-property-decorator";
import { Getter, Action } from "vuex-class";

@Component({
    components: {},
})
export default class DossierTabPartContactBuyers extends Vue {
    @Getter("activity/viewing") activity!: Activity;
    @Getter("bid/winningBid") winningBid!: Bid | undefined;
    @Action("bid/index") indexBids!: BidIndex;

    loading = false;

    async mounted() {
        this.loading = true;

        await this.indexBids({ activity_id: this.activity.id });

        this.loading = false;
    }
}
