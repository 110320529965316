import { Mandate } from "@/store/modules/mandate.store";
import Vue from "vue";
import Component from "vue-class-component";
import { Getter, Action } from "vuex-class";

@Component
export class HasNotary extends Vue {
  @Getter("activity/viewing") activity!: Activity;
  @Getter("mandate/viewing") mandate!: Mandate | undefined;
  @Getter("bid/winningBid") winningBid!: Bid | undefined;

  get sellerNotary() {
    if (!this.mandate || !this.mandate.details || !this.mandate?.details?.article_2_notary) {
      return [];
    }

    return [{ name: this.mandate.details.article_2_notary, type: "SELLER", canReset: true, canEdit: true, canDelete: false }];
  }

  get buyerNotary() {
    if (this.activity && this.activity.buyer_notary) {
      return [{ name: this.activity.buyer_notary, type: "BUYER", canReset: false, canEdit: true, canDelete: true }];
    }

    if (!this.winningBid || !this.winningBid?.signing_details || !this.winningBid?.signing_details?.notary_info) {
      return [];
    }

    return [{ name: this.winningBid!.signing_details!.notary_info, type: "BUYER", canReset: true, canEdit: false, canDelete: false }];
  }
}
