
import { Component, Mixins } from "vue-property-decorator";
import { bus } from "@/main";
import { HasSellers } from "@/mixins/has-sellers";
import { HasSellersWithRelation } from "@/mixins/has-sellers-with-relation";

@Component({
    components: {
        Spinner: () => import("@/components/general/spinner.vue"),
        SellerInfo: () => import("@/components/seller/seller-info.vue"),
    },
})
export default class DossierTabPartContactSellers extends Mixins(HasSellers, HasSellersWithRelation) {
    loading = false;

    bus = bus;

    async mounted() {
        this.loading = true;

        await this.getSellers();

        this.loading = false;
    }

    get canPulse() {
        if (!this.$route.query) {
            return false;
        }
        return this.$route.query.pulse === "sellers";
    }
}
