
import { Component, Vue } from "vue-property-decorator";
import { activityIsDossier } from "@/utils/model-helpers";
import { Getter } from "vuex-class";

import ContactBuyersPart from "@/views/auth/dossier/tabs/parts/contacts/buyers.vue";
import ContactSellersPart from "@/views/auth/dossier/tabs/parts/contacts/sellers.vue";
import ContactNotaryPart from "@/views/auth/dossier/tabs/parts/contacts/notary.vue";

@Component({
    components: {
        ContactBuyersPart,
        ContactSellersPart,
        ContactNotaryPart,
    },
})
export default class DossierTabContacts extends Vue {
    @Getter("activity/viewing") activity!: Activity;

    get isDossier() {
        return activityIsDossier(this.activity);
    }
}
