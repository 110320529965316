
  import { Component, Mixins } from "vue-property-decorator";
  import { HasNotary } from "@/mixins/has-notary";
  import { Action } from "vuex-class";
  import { bus } from "@/main";

  @Component({
    components: {
      Spinner: () => import("@/components/general/spinner.vue"),
      NotaryInfo: () => import("@/components/dossier/contacts/notary-info.vue"),
      AddNotaryModal: () => import("@/components/modals/notary/add-notary.vue"),
      UpdateNotaryModal: () => import("@/components/modals/notary/update-notary.vue"),
      DeleteNotaryModal: () => import("@/components/modals/notary/delete-notary.vue"),
    },
  })
  export default class DossierTabPartContactNotaries extends Mixins(HasNotary) {
    @Action("bid/index") indexBids!: BidIndex;

    loading = false;

    bus = bus;

    async mounted() {
      this.loading = true;

      await this.indexBids({ activity_id: this.activity.id });

      this.loading = false;
    }
  }
